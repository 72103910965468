<template>
    <div>
        <div class="page-title">帮助中心</div>

        <div class="search-tab">
            <at-input v-model="keywords" unit="搜索问题" class="mb-4" width="500px"
                      @change="search"
                      placeholder="请简单描述您想搜索的问题">
                <button @click="search" class="btn">搜索</button>
            </at-input>
        </div>

        <div class="helpers-tools">
            <button class="btn helper-btn"
                    :class="{active: selectedCateId === 0 }"
                    @click="selectCate(0)">
                <span>全部</span>
            </button>
            <template v-for="(item,hk) in helpers">
                <template v-if=" item.parent_id === 0 && item.id ">
                    <button class="btn helper-btn" :key="hk"
                            :class="{active: selectedCateId === item.id }"
                            @click="selectCate(item.id)">
                        <span>{{ item.title }}</span>
                    </button>
                </template>
            </template>
        </div>


        <div class="helpers">

            <template v-for="(item,hk) in helpers">

                <div v-if="item.items.length && ((!selectedCateId) || (item.parent_id === selectedCateId) )"
                     class="helper-item" :key="hk">

                    <div class="helper-item-header">

                        {{ item.title }}

                    </div>

                    <ul class="helper-item-content">
                        <template v-for="(sub) in item.items">

                            <li class="helper-sub-item" :key="sub.id">
                                <router-link target="_blank" :to="`/ast/helpers/${sub.id}`">
                                    <span>{{ sub.title }}</span>
                                </router-link>
                            </li>

                        </template>
                    </ul>

                </div>

            </template>
        </div>
    </div>
</template>

<script>
import api from "@/repo/api";
import AtInput from "@/components/AtInput.vue";

export default {
    name: "Index",
    components: {AtInput},
    data() {
        return {
            keywords: '',
            helpers: [],
            originList: [],
            selectedCateId: 0,
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {

            this.$loading.show()

            api.get('/ast-app/books', res => {

                this.$loading.hide()

                this.helpers = this.originList = res.data

            })
        },
        search() {
            this.filterList()
        },
        selectCate(id) {
            this.selectedCateId = id
        },
        filterList() {
            if (!this.keywords) return this.helpers = this.originList

            let list = []
            let origin = JSON.parse(JSON.stringify(this.originList))

            origin.forEach(item => {

                let match = false;

                if (item.title.indexOf(this.keywords) > -1) {
                    match = true
                }

                if (item.parent_id === 0) {
                    match = true
                }

                let subs = []

                item.items.forEach(sub => {

                    if (sub.title.indexOf(this.keywords) > -1) {
                        match = true

                        subs.push(sub)
                    }

                })

                if (match) {
                    item.status = true
                    item.items = subs
                    list.push(item)
                }

            })

            this.helpers = list
        },
    }
}
</script>

<style scoped lang="less">

.helper-item-header {
    height: 48px;
    line-height: 48px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    padding-left: 60px;
    background-image: url("/assets/icons/helper-icon.png");
    background-size: 48px;
    background-repeat: no-repeat;
    margin-bottom: 8px;
}

.helper-item {
    margin-top: 24px;
    overflow: hidden;
    padding: 20px 28px;

    transition: all .3s;
    background-image: url("/assets/images/helper-bg.png");
    background-size: cover;

    &:hover {
        background: #FFFFFF;
        box-shadow: 0 4px 10px 0 rgba(44, 113, 220, 0.2);
    }
}

.helpers {
    margin-bottom: 48px;
}

.helper-item-content {
    padding-left: 12px;

    li {
        float: left;
        width: 33.33%;
        padding-right: 24px;
        margin-bottom: 8px;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
    }
}

.helper-sub-item a {
    color: initial;
}

.helpers-tools {
    margin-top: 16px;

    .btn {
        color: #999999;
        background-color: #FFFFFF;
        border: 1px solid #EEEEEE;
        margin-right: 16px;
        margin-bottom: 16px;

        &:hover, &.active {
            color: #FFFFFF;
            background-color: #333;
        }
    }
}
</style>